import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Alert,
  CircularProgress
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoginImg from '../../assets/images/Login.jpeg';
import { Link, useNavigate } from 'react-router-dom';

import SellNow from './SellNow';
import Warranty from './Warranty';
import axios from 'axios';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Email from '@mui/icons-material/Email';
import { config } from '../../config';



                  
const LoginSignupPage = ({selectedModel, deviceAnswers, formattedVariant, warrantyStatus, goBack, currentValue, rowDetails, answers, FixedPrice,ScreenselectedOptions,DefectsselectedOptions,functionalSelectedOptions,accSelectedOptions,userLogDetails,onFormData}) => {
  const [currentPage, setCurrentPage] = useState('loginpage');
  const [showState,setShowState] = useState('PhoneNumber');
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [isResendAllowed, setIsResendAllowed] = useState(true);
  const [countdown, setCountdown] = useState(60);
  const [formData, setFormData] = useState({name: '',email: '',phoneNumber: '', BrandName : rowDetails["Brand name"], Model : rowDetails["Model"],formattedVariant:formattedVariant});
  const[userLogDetails1,setuserLogDetails1] = useState(null);
  const [errors, setErrors] = useState({});
   const [newUser, setNewUser] = useState(false);
   const [loading, setLoading] = useState(false);
   const [uniqueId,setuniqueId] = useState(0);

  useEffect(() => {
    let timer;
    if (!isResendAllowed) {
      timer = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setIsResendAllowed(true);
            return 60;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isResendAllowed]);

  const handleSendOtp = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${config.Api}send-otp`, {phoneNumber});
      console.log(response,"response...")
      setLoading(false);
      if (response.status === 200) {
        setIsOtpSent(true);
        setIsResendAllowed(false);
        setShowState('otp');
      } else {
        // alert(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      // alert('Failed to send OTP');
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${config.Api}verify-otp`, { phoneNumber, otp });
      setLoading(false);
      console.log(response,"verify")
      if (response.status === 200) {
        setCurrentPage('sellNowPage');
        setShowState(null);
        setOtpError('');
      } else {
        setOtpError(response.data.message);
        setShowState('otp')
      }
    } catch (error) {
      setLoading(false);
      setOtpError('Failed to verify OTP');
    }
  };

  
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handlePhoneNumberSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${config.Api}check-phone-number`, { phoneNumber });
      setLoading(false);
      if (response.data.exists) {
        // setCurrentPage('details');
        console.log(response.data.user,"response.data.user");
        const { name, email, phoneNumber } = response.data.user;
        console.log(name, email, phoneNumber,"name, email, phoneNumber")
        // setShowState('otp');
        setuserLogDetails1({name, email, phoneNumber});
        setCurrentPage("sellNowPage");
        // navigate('/sell-now', { state: { userName: name, selectedModel, deviceAnswers, formattedVariant, warrantyStatus, goBack, currentValue, rowDetails, answers, FixedPrice,ScreenselectedOptions,DefectsselectedOptions,functionalSelectedOptions,accSelectedOptions } });

        // setCurrentPage('otp');
        // handleSendOtp();
  
      } else {
        setNewUser(true);
        setShowState('details');
        // handleSendOtp();
        setFormData({ ...formData, phoneNumber });
      }
    } catch (error) {      
      setLoading(false);
      alert('Error checking phone number');
    }
  };
  const handleContinue = async () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone Number is required';
    if (Object.keys(newErrors).length === 0) {
      setuserLogDetails1({ name: formData.name, email: formData.email, phoneNumber: formData.phoneNumber });

      try {
      const url = uniqueId ? `${config.Api}userpickup/${uniqueId}` : config.Api+'userpickup';
      const method ='POST';
      const response = await fetch(url, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

        if (response.ok) {
          const result = await response.json(); // Parse the JSON response
          console.log(result,"result")
          const savedPickupDetails = result.data;
          console.log('Saved Pickup Details:', savedPickupDetails);
          setuniqueId(savedPickupDetails?.uniqueId);
          // Clear form data after successful submission if needed
          setFormData({
            name: '',
            email: '',
            phoneNumber: '',

           });
        } else {
          // setPickupDetails([]);
          throw new Error('Failed to submit details');
        }
      } catch (error) {
        console.log(error)
        }finally {
        setLoading(false); // Stop loading after processing
      }
      if (onFormData) {
        onFormData(formData); // Send data to parent
      }
      // setShowState('otp')
      setCurrentPage('sellNowPage');
    } else {
      setErrors(newErrors);
    } 
  };
  

  return (
    <>
     {currentPage === "loginpage" && (
        <Grid container spacing={2} sx={{ mt: 4 }} justifyContent="center">
      <Paper elevation={3} style={{ display: 'flex', padding: '20px',maxWidth:"1000px" }}>
        <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ backgroundColor: '#36225b', padding: '20px' }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <img
                src={LoginImg ? LoginImg : "https://via.placeholder.com/300x300?text=Login+Image"}
                alt="Login Illustration"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
          
            <Typography variant="h6" gutterBottom>
            {`${rowDetails?.["Brand name"]} ${rowDetails?.["Model"]} (${formattedVariant})` || 'N/A'}
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ color: 'red' }}>
                  ₹ XX,XXX
                </Typography>
              <Typography variant="h6">Login To Unlock the Price</Typography>
            {showState === "PhoneNumber" && (
              <>
               <TextField
                      fullWidth
                      name="phoneNumber"
                      label="Phone Number"
                      type="tel"
                      value={phoneNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 10) {
                          setPhoneNumber(value);
                          setErrors({ ...errors, phoneNumber: value.length < 10 ? 'Phone number must be 10 digits' : '' });
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalPhoneIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber}
                      sx={{ mt: 2 }}
                    />

                   <Button
                      variant="contained"
                      fullWidth
                      sx={{ mt: 4, backgroundColor: 'lightgreen', color: 'white', '&:hover': { backgroundColor: 'green' } }}
                      onClick={handlePhoneNumberSubmit}
                      endIcon={loading ? <CircularProgress size={24} /> : <ArrowForwardIcon />}
                      disabled={loading}
                    >
                      {loading ? 'Sending...' : 'Continue'}
                    </Button>
                  </>

            )}
           
        
        
        {showState === 'otp' && (
          <>
            <Button
              variant="contained"
              fullWidth
              sx={{ backgroundColor: '#D8F2E8', color: '#06A89D', marginBottom: '20px', '&:hover': { backgroundColor: '#06A89D', color: '#fff' } }}
              onClick={handleSendOtp}
              disabled={!isResendAllowed && isOtpSent}
            >
              {isResendAllowed ? 'Send OTP' : `Resend OTP in ${countdown}s`}
            </Button>
            <Typography variant="body2" gutterBottom>
              Enter OTP
            </Typography>
            <Grid container spacing={1}>
              {[...Array(6)].map((_, index) => (
                <Grid item xs={2} key={index}>
                  <TextField
                    variant="outlined"
                    value={otp[index] || ''}
                    onChange={(e) => {
                      const newOtp = otp.split('');
                      newOtp[index] = e.target.value;
                      setOtp(newOtp.join(''));
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            {otpError && <Alert severity="error" sx={{ mt: 2 }}>{otpError}</Alert>}
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 4, backgroundColor: 'lightgreen', color: 'white' }}
              onClick={handleVerifyOtp}
              endIcon={loading ? <CircularProgress size={24} /> : <ArrowForwardIcon />}
              disabled={loading}
            >
                      {loading ? 'Verifying...' : 'Verify OTP'}
                      </Button>
            <FormControlLabel
              control={<Checkbox name="checkedA" />}
              label={
                <Typography variant="body2">
                  I agree to the <Link to="/terms" style={{ textDecoration: "underline" }} onClick={() => navigate('/terms')}>Terms and Conditions</Link> & <a href="#privacy">Privacy Policy</a>
                </Typography>
              }
              sx={{ mt: 2 }}

            />
          </>
        )}
        {showState === 'details' && (
          <>
      <Grid container spacing={2} sx={{ mt: 3, px: 2 }}>
  <Grid item xs={12} sm={6}>
    <TextField
      fullWidth
      name="name"
      label="Full Name"
      type="text"
      value={formData.name}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircle />
          </InputAdornment>
        ),
      }}
      error={!!errors.name}
      helperText={errors.name}
      sx={{ 
        mt: 2,
        '& .MuiInputBase-root': { 
          backgroundColor: 'white' 
        },
      }}
    />
  </Grid>

  <Grid item xs={12} sm={6}>
    <TextField
      fullWidth
      name="email"
      label="Email Address"
      type="email"
      value={formData.email}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Email />
          </InputAdornment>
        ),
      }}
      error={!!errors.email}
      helperText={errors.email}
      sx={{ 
        mt: 2,
        '& .MuiInputBase-root': { 
          backgroundColor: 'white' 
        },
      }}
    />
  </Grid>

  <Grid item xs={12}>
    <Button
      variant="contained"
      fullWidth
      sx={{
        mt: 4,
        py: 1.5,
        backgroundColor: 'lightgreen',
        color: 'white',
        fontSize: '1rem',
        '&:hover': { backgroundColor: 'green' },
        borderRadius: '8px',
        transition: 'all 0.3s ease-in-out',
      }}
      onClick={handleContinue}
      endIcon={loading ? <CircularProgress size={24} /> : <ArrowForwardIcon />}
      disabled={loading}
    >
      {loading ? 'Submitting...' : 'Continue'}
    </Button>
  </Grid>
</Grid>

          </>
        )}
         </Grid>
        </Grid>
      </Paper>
      </Grid> )}
    
   

       <Grid container spacing={2} sx={{ mt: 4 }}>
        <>
        {currentPage === 'sellNowPage' && 
        <SellNow 
        selectedModel={selectedModel}
         formattedVariant={formattedVariant}
          currentValue={currentValue}
           warrantyStatus={warrantyStatus}
            rowDetails={rowDetails}
             answers={answers}
              FixedPrice={FixedPrice} 
              deviceAnswers={deviceAnswers}
               ScreenselectedOptions={ScreenselectedOptions}
                DefectsselectedOptions={DefectsselectedOptions} 
                functionalSelectedOptions={functionalSelectedOptions}
                 accSelectedOptions={accSelectedOptions} 
                 userLogDetails={userLogDetails1} 
                 onFormData={onFormData}
                 
                 />
                 }

                 
        {currentPage === 'warrantyPage' &&
         <Warranty 
         selectedModel={selectedModel} 
         formattedVariant={formattedVariant}
          currentValue={currentValue} 
          warrantyStatus={warrantyStatus}
          rowDetails={rowDetails}
            answers={answers}
             FixedPrice={FixedPrice}
              deviceAnswers={deviceAnswers}
               ScreenselectedOptions={ScreenselectedOptions}
                DefectsselectedOptions={DefectsselectedOptions}
                 functionalSelectedOptions={functionalSelectedOptions}
                  accSelectedOptions={accSelectedOptions}
                   userLogDetails={userLogDetails1} 
                   onFormData={onFormData}
                   />}
        </>
       </Grid>
       </>
  );
};

export default LoginSignupPage;