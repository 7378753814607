import React,{useState} from 'react';
import { Container, Typography, Paper, Grid, Button, Box,Dialog, DialogTitle, DialogContent, DialogActions,useMediaQuery } from '@mui/material';
import BillingAddressForm from './BillingAddress';
import Pickup from './Pickup'
import CancelOrderDialog from './CancelReason.js';
import Tick from "../../assets/images/Tick.jpeg";
import CloseIcon from '@mui/icons-material/Close';
import DeviceEvaluation from '../commonForms/DeviceEvaluation.js';
import axios from 'axios';
import "../../assets/css/confirmation.css"
import CancelIcon from '@mui/icons-material/Cancel';
import ReplayIcon from '@mui/icons-material/Replay';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportIcon from '@mui/icons-material/Report';
import { config } from '../../config';
import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ'; 

const ConfirmationPage = ({selectedModel,  deviceAnswers,formattedVariant,warrantyStatus,goBack,currentValue,rowDetails,answers,PickupDetails,FixedPrice,ScreenselectedOptions,DefectsselectedOptions,functionalSelectedOptions,accSelectedOptions }) => {
  const [currentPage, setCurrentPage] = useState('Confirmation');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false); // State for report dialog
  const[OrderCancelled,setOrderCancelled] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)');

 

  if(currentPage == "Billing Address"){
    return <BillingAddressForm/>
  }
  const handleReschedule = () => {
    setCurrentPage("Pickup");
  }
  const handleCancelClick = () => {
    setCancelDialogOpen(true);
  };

  const handleCancelClose = () => {
    setCancelDialogOpen(false);
  };
  const handleCancelConfirm = async (reason) => {
    try {
      const uniqueId = PickupDetails.uniqueId; // Ensure this value is accessible here
      const status = 'Failed'; // Define the status as needed
  
      const response = await axios.post(`${config.Api}update-status/${uniqueId}`, { status, reason });
  
      if (response.status === 200) {
       alert("Order cancelled successfully");
       setOrderCancelled(true);
        // You can add any additional logic here if needed, like showing a success message to the user
      } else {
        alert("Failed to cancel order");
        setOrderCancelled(false);

        // You can add any additional logic here if needed, like showing an error message to the user
      }
    } catch (error) {
      console.error("Error cancelling order:", error);
      // Handle error appropriately, maybe show an error message to the user
    }
  
    setCancelDialogOpen(false);
  };
  if(currentPage === "Pickup"){
    return <Pickup uniqueId={PickupDetails?.uniqueId} modifyPickupDetails={PickupDetails}  selectedModel={selectedModel} formattedVariant={formattedVariant} currentValue={currentValue} warrantyStatus={warrantyStatus} rowDetails={rowDetails} answers={answers} FixedPrice={FixedPrice} deviceAnswers={deviceAnswers} ScreenselectedOptions={ScreenselectedOptions} DefectsselectedOptions={DefectsselectedOptions} functionalSelectedOptions={functionalSelectedOptions} accSelectedOptions={accSelectedOptions} />
  }

  const handleReportOpen = () => {
    setReportDialogOpen(true);
  };

  const handleReportClose = () => {
    setReportDialogOpen(false);
  };

  return (
    <>
    {currentPage === "Confirmation" && (
        <Container maxWidth="sm">
          <Paper elevation={3} className="confirmation-paper">
          <Box mb={3} className="confirmation-icon-box">
          {!OrderCancelled ? 
                <img src={Tick} alt="Success Icon" className="confirmation-icon" />
                : 
          
                <CloseIcon className="confirmation-icon error-icon" />
             }
              
            </Box>
            <Typography variant="h4" gutterBottom className={OrderCancelled ? "cancel-order" : "confirm-order"}>
              {!OrderCancelled ? "Thank You!" : "Oops!"}
            </Typography>
            <Typography variant="body1" gutterBottom className={OrderCancelled ? "cancel-order" : "confirm-order"}>
              {!OrderCancelled ? "Your order has been confirmed" : "Your order has been cancelled"}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Service Number: <strong>{PickupDetails.uniqueId}</strong>
            </Typography>
            <Paper elevation={1} className="device-details-paper">
              <Typography variant="h6" gutterBottom>
                Device Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                <img
                    src={rowDetails[" Image URL"] || ""}
                    alt="Device"
                    className="device-image"
                  />
                </Grid>
                <Grid item xs={12} sm={8} className="device-info">
                  <Box mb={1} display="flex" justifyContent="space-between">
                  <Typography variant="body1" className="device-name">
                  {PickupDetails.BrandName} {PickupDetails.Model} ({PickupDetails.formattedVariant})
                    </Typography>
                    <Button    startIcon={<CheckCircleIcon />}  // Added icon
 variant="outlined" size="small" className="pickup-request-button">
                      Pickup Requested
                    </Button>
                  </Box>
                  <Typography variant="body2">
                    Order created on: <strong>{new Date(PickupDetails.createdAt).toLocaleDateString()}</strong>
                  </Typography>
                  <Typography variant="body2">
                    Quote Price: <strong>₹{PickupDetails.QuotedPrice}</strong>
                  </Typography>
                  <Typography variant="body2">
                  <a href="#view-device-report" onClick={handleReportOpen} className="view-report-link"><ReportIcon   fontSize="small" style={{ marginRight: '4px' }} />
                  View Device Report</a>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={1} className="order-details-paper">
              <Typography variant="h6" gutterBottom>
                Order Details
              </Typography>
              <Box mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body2">Device</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" align="right">
                      {PickupDetails.BrandName} {PickupDetails.Model} ({PickupDetails.formattedVariant})
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Order Date</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" align="right">
                      {new Date(PickupDetails.createdAt).toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Address</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" align="right">
                      {PickupDetails.address}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Schedule Date & Time</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" align="right">
                    {new Date(PickupDetails.pickupDate).toLocaleDateString()} ({PickupDetails.pickupTimeOption})
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                <Button 
                style={{fontSize:"12px"}}
                startIcon={<CancelIcon />}  // Added icon
disabled={OrderCancelled ? true : false} variant="outlined"   color="error" onClick={handleCancelClick} className={OrderCancelled ? "disable-cancel-order-button" : "cancel-order-button"}>
                Cancel Order
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                <Button  
                  style={{fontSize:"12px"}}   
                startIcon={<ReplayIcon />}  // Added icon
 variant="contained"   color="primary" onClick={handleReschedule} disabled={OrderCancelled ? true : false} className={OrderCancelled ? "disable-cancel-order-button" :"reschedule-button"  }>
                Reschedule
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  
                </Grid>
              </Grid>
            </Paper>
          </Paper>
          <CancelOrderDialog open={cancelDialogOpen} onClose={handleCancelClose} onConfirm={handleCancelConfirm} />

          <Dialog open={reportDialogOpen} onClose={handleReportClose} maxWidth="sm" fullWidth>
            <DialogTitle>Device Evaluation</DialogTitle>
            <DialogContent>
              <DeviceEvaluation selectedModel={selectedModel} deviceAnswers={deviceAnswers} formattedVariant={formattedVariant} answers={answers} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleReportClose} color="primary">Close</Button>
            </DialogActions>
          </Dialog>


          {!isMobile && (
      <>
    <FAQ  />
<Footer />
</>
)}

          </Container>

          
      )}
    
    </>
    
  );
};

export default ConfirmationPage;
