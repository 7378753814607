import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Grid, TextField, MenuItem, Card, CardContent, FormControl,FormLabel, InputLabel, Select, FormHelperText, Alert, InputAdornment, Radio, RadioGroup, FormControlLabel,useMediaQuery } from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { AccountCircle, Email, Home, Payment as PaymentIcon, LocationOn, LocalPhone, CalendarToday, AccessTime } from '@mui/icons-material';
import { addDays, isBefore, startOfToday, setHours, setMinutes } from 'date-fns';
import ConfirmationPage from './Confirmation';
import Loader from "../commonForms/LoaderComponent";
import CircularProgress from '@mui/material/CircularProgress';
import { config } from '../../config';
import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ'; 

const PickupDateSelector = ({ modifyPickupDetails,selectedModel,  deviceAnswers,formattedVariant,warrantyStatus,goBack,currentValue,rowDetails,answers,FixedPrice,ScreenselectedOptions,DefectsselectedOptions,functionalSelectedOptions,accSelectedOptions,uniqueId,userLogDetails,onFormData}) => {
  console.log(userLogDetails,"userLogDetails");
 
  const [selectedDate, setSelectedDate] = useState(null);
  const[loading,setLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [dates, setDates] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  const [currentPage, setCurrentPage] = useState('pickupDateSelector');
  const [pickupTimeOption, setPickupTimeOption] = useState('10am to 2pm'); // State for pickup time option
  const isMobile = useMediaQuery('(max-width:600px)');

  const [formData, setFormData] = useState({
    name: userLogDetails?.name || '',
    email: userLogDetails?.email || '',
    address: '',
    pinCode:'',
    phoneNumber: userLogDetails?.phoneNumber || '',
    paymentOption: '',
    pickupDate: null,
    pickupTime: null,
    pickupTimeOption: '10am to 2pm', // Include pickup time option in formData
    QuotedPrice : currentValue,
    BrandName : rowDetails["Brand name"],
    Model : rowDetails["Model"],
    ImageURL : rowDetails[" Image URL"],
    answers: answers || {},
    deviceAnswers: deviceAnswers || {},
    screenOptions: ScreenselectedOptions || [],
    defectOptions: DefectsselectedOptions || [],
    functionalOptions: functionalSelectedOptions || [],
    accessoryOptions: accSelectedOptions || [],
    formattedVariant : formattedVariant
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const[PickupDetails,setPickupDetails] = useState([]);


  useEffect(() => {
    if (modifyPickupDetails) {
      setFormData(prevData => ({
        ...prevData,
        ...modifyPickupDetails
      }));
    }
  }, [modifyPickupDetails]);

  useEffect(() => {
    const today = startOfToday();
    const nextDays = [];
    for (let i = 0; i < 4; i++) {
      const nextDay = addDays(today, i);
      if (i === 0 && new Date().getHours() >= 14) {
        continue;
      }
      nextDays.push(nextDay);
    }
    setDates(nextDays);
  }, []);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setFormData(prevData => ({
      ...prevData,
      pickupDate: date,
    }));
    setSelectedTime(null); // Reset time when date changes
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    setFormData(prevData => ({
      ...prevData,
      pickupTime: time,
    }));
  };
  const handlePickupTimeOptionChange = (e) => {
    const value = e.target.value;
    setPickupTimeOption(value);
    setFormData(prevData => ({
      ...prevData,
      pickupTimeOption: value,
    }));
  };


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const  handleSubmit = async () => {
    setLoading(true);
    // Basic validation
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.address) newErrors.address = 'Address is required';
    if (!formData.pinCode) newErrors.pinCode = 'Pin Code is required';
    if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone Number is required';
    if (!formData.paymentOption) newErrors.paymentOption = 'Payment option is required';
    if (!formData.pickupDate) newErrors.pickupDate = 'Pickup date is required';
    if (!formData.pickupTimeOption) newErrors.pickupTimeOption = 'Pickup time is required';
    if (Object.keys(newErrors).length === 0) {
      try {
        const url = uniqueId ? `${config.Api}userpickup/${uniqueId}` : config.Api+'userpickup';

      const method = uniqueId ? 'POST' : 'POST';
      const response = await fetch(url, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

        if (response.ok) {
          const result = await response.json(); // Parse the JSON response
          console.log(result,"result")
          setMessage('Details submitted successfully!');
          setCurrentPage("Confirmation");
          setMessageType('success');
          const savedPickupDetails = result.data;
          setPickupDetails(result.data);
          console.log('Saved Pickup Details:', savedPickupDetails);
          // Clear form data after successful submission if needed
          setFormData({
            name: '',
            email: '',
            address: '',
            pinCode: '',
            phoneNumber: '',
            paymentOption: '',
            pickupDate: null,
            pickupTime: null,
            pickupTimeOption: '10am to 2pm', // Reset to default value
            answers: {},
            deviceAnswers: {},
            screenOptions: [],
            defectOptions: [],
            functionalOptions: [],
            accessoryOptions: [],
          });
        } else {
          // setPickupDetails([]);
          throw new Error('Failed to submit details');
        }
      } catch (error) {
        setMessage('Error submitting details. Please try again.');
        setMessageType('error');
       }finally {
        setLoading(false); // Stop loading after processing
      }
    } 
    else {
      setErrors(newErrors);
      setMessage('Please correct the errors in the form.');
      setMessageType('error');
      setLoading(false); // Stop loading even if there are validation errors
    }
  };
  const disablePastDates = (date) => {
    return isBefore(date, startOfToday());
  };

  const disablePastTimes = (time) => {
    if (!selectedDate) {
      return false;
    }
    const now = new Date();
    if (selectedDate.toDateString() === now.toDateString()) {
      return isBefore(time, now);
    }
    return false;
  };

  if(currentPage === "Confirmation"){
     return <ConfirmationPage PickupDetails={PickupDetails}  selectedModel={selectedModel} formattedVariant={formattedVariant} currentValue={currentValue} warrantyStatus={warrantyStatus} rowDetails={rowDetails} answers={answers} FixedPrice={FixedPrice} deviceAnswers={deviceAnswers} ScreenselectedOptions={ScreenselectedOptions} DefectsselectedOptions={DefectsselectedOptions} functionalSelectedOptions={functionalSelectedOptions} accSelectedOptions={accSelectedOptions} onFormData={onFormData}/>
  }
  return (
    

    <Container maxWidth="sm">
      <Card sx={{ boxShadow: 3, borderRadius: 2, transition: 'all 0.3s ease' }}>
      <CardContent>
      
          <Typography variant="h6" gutterBottom>{uniqueId ? "Edit Pickup Details":"Add Pickup Details"}

          </Typography>
        {message && (
            <Alert severity={messageType} sx={{ mb: 2 }}>
              {message}
            </Alert>
          )}
          <Grid container spacing={2}>
      
            
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="name"
                    label="Name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ '& .MuiTextField-root': { transition: '0.3s' }, '&:hover .MuiTextField-root': { borderColor: 'primary.main' } }}

                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="email"
                    label="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ '& .MuiTextField-root': { transition: '0.3s' }, '&:hover .MuiTextField-root': { borderColor: 'primary.main' } }}

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="address"
                    label="Address"
                    value={formData.address}
                    onChange={handleChange}
                    error={!!errors.address}
                    helperText={errors.address}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Home />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ '& .MuiTextField-root': { transition: '0.3s' }, '&:hover .MuiTextField-root': { borderColor: 'primary.main' } }}

                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="pinCode"
                    label="Pin Code"
                    type="number"

                    value={formData.pinCode}
                    onChange={handleChange}
                    error={!!errors.pinCode}
                    helperText={errors.pinCode}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOn />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ '& .MuiTextField-root': { transition: '0.3s' }, '&:hover .MuiTextField-root': { borderColor: 'primary.main' } }}

                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="phoneNumber"
                    label="Phone Number"
                    type="tel"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocalPhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ '& .MuiTextField-root': { transition: '0.3s' }, '&:hover .MuiTextField-root': { borderColor: 'primary.main' } }}

                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth error={!!errors.paymentOption}>
                    <InputLabel>Payment Option</InputLabel>
                    <Select
                      name="paymentOption"
                      value={formData.paymentOption}
                      onChange={handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <PaymentIcon />
                        </InputAdornment>
                      }
                    >
                      <MenuItem value="Google Pay">Google Pay</MenuItem>
                      <MenuItem value="Phone Pe">Phone Pe</MenuItem>
                      <MenuItem value="Paytm">Paytm</MenuItem>
                      <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                    </Select>
                    <FormHelperText>{errors.paymentOption}</FormHelperText>
                    </FormControl>
                </Grid>
           
         
            <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.pickupDate}>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Pickup Date"
                  value={formData.pickupDate}
                  onChange={handleDateSelect}
                  minDate={new Date()}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  shouldDisableDate={disablePastDates}

                />
              </LocalizationProvider>
              <FormHelperText>{errors.pickupDate}</FormHelperText>
              </FormControl>

              </Grid>
         
              {selectedDate && (
              <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Pickup Time</FormLabel>
                <RadioGroup row value={pickupTimeOption} onChange={handlePickupTimeOptionChange}>
                  <FormControlLabel value="10am to 2pm" control={<Radio />} label="10am to 2pm" />
                  <FormControlLabel value="2pm to 6pm" control={<Radio />} label="2pm to 6pm" />
                </RadioGroup>
              </FormControl>
            </Grid>
            )}
                    <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {!isMobile && (
      <>
    <FAQ  />
<Footer />
</>
)}
    </Container>
      
  );
};

export default PickupDateSelector;
